$plp-sorting-field-text-transform: uppercase;
$plp-results-count-font: 400 #{rem-calc(14)} / rem-calc(20) $font-primary;
$plp-slot-top-margin: 0;
$plp-results-count-margin: rem-calc(0 30 0 0);
$plp-results-count-color: color(text-secondary);

// Product Results
$plp-tools-border-bottom--large: 0;

$plp-padding--large: rem-calc(0 0 50);

@import "@lora/04-layout/plp";