$search-plp-top-banner-text-align: left;
$search-plp-top-banner-background: color(primary);

// Category name
$search-plp-top-banner-category-name-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$search-plp-top-banner-category-name-margin: rem-calc(25);
$search-plp-top-banner-category-name-display: inline-block;
$search-plp-top-banner-category-name-with-category-cover-display: inline-block;

// Results count
$search-plp-top-banner-results-count-font: $search-plp-top-banner-category-name-font;
$search-plp-top-banner-results-count-display: inline-block;
$search-plp-top-banner-results-count-margin: rem-calc(15 0 15 25);

// Category Cover display
$search-plp-top-banner-category-cover-display: block;

@import "@lora/05-components/search/search-plp-top-banner";